import { render, staticRenderFns } from "./DevSettingsModal.vue?vue&type=template&id=dfd5e984&scoped=true&"
import script from "./DevSettingsModal.vue?vue&type=script&lang=js&"
export * from "./DevSettingsModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfd5e984",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/echope/echope-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dfd5e984')) {
      api.createRecord('dfd5e984', component.options)
    } else {
      api.reload('dfd5e984', component.options)
    }
    module.hot.accept("./DevSettingsModal.vue?vue&type=template&id=dfd5e984&scoped=true&", function () {
      api.rerender('dfd5e984', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/dev/DevSettingsModal.vue"
export default component.exports