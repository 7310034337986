<template>
  <section>
    <FormulateForm
      v-if="product"
      ref="formProduct"
      name="formProduct"
      @submit="localSaveProduct"
    >
      <b-card-actions
        action-collapse
        :show-loading="fetching || saving"
        :title="productDescription
          ? `${product.id ? `#${product.id} - ` : ''}${productDescription}`
          : $t('Produto')
        "
      >
        <b-row>
          <!-- image side -->
          <b-col md="3">
            <b-row>
              <b-col md="12">
                <b-form-group class="w-100 border-0">
                  <b-form-radio-group
                    id="active"
                    v-model="product.active"
                    :options="[
                      { value: true, text: 'Ativo' },
                      { value: false, text: 'Inativo' },
                    ]"
                    button-variant="primary"
                    size="md"
                    class="w-100 border-0"
                    buttons
                    :disabled="isReadOnly"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-tabs fill>
                  <b-tab
                    active
                    :title="$t('Quadrada')"
                  >
                    <FormulateInput
                      id="image-square-local"
                      v-model="imageSquareLocal"
                      :type="isReadOnly ? 'image' : 'uploader'"
                      deletable="true"
                      max-files="1"
                      :meta="false"
                    />
                  </b-tab>
                  <b-tab :title="$t('Wide')">
                    <FormulateInput
                      id="image-wide-local"
                      v-model="imageWideLocal"
                      :type="isReadOnly ? 'image' : 'uploader'"
                      deletable="true"
                      max-files="1"
                      :meta="false"
                    />
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <div style="overflow-x: auto">
                  <barcode
                    class="text-center"
                    :value="product.ean || 1234567890"
                  >
                    EAN
                  </barcode>
                </div>
                <FormulateInput
                  v-if="!isReadOnly"
                  id="ean"
                  v-model.trim="product.ean"
                  class="mt-1"
                  :label="$t('Código de Barras (EAN/GTIN)')"
                  type="text"
                  inputmode="numeric"
                  :pattern="/[0-9]*/"
                  maxlength="15"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col md="9">
            <product-form
              ref="productForm"
              :purchase-order-product="purchaseOrderProduct"
            />
          </b-col>
        </b-row>
      </b-card-actions>

      <b-card-actions
        v-if="product.integrateWithBeerCooler"
        action-collapse
        :show-loading="fetching || saving"
        :title="$t('Mídia da Chopeira')"
      >
        <tap-station-media-form
          :files="localTapStationMedia"
          :is-read-only="isReadOnly"
          @set-files="setTapStationMedia"
          @remove-file="removeTapStationMedia"
        />
      </b-card-actions>

      <b-row>
        <b-col md="12">
          <sku-form
            ref="skuForm"
            :fetching="fetching || fetchingPriceTables || saving"
          />
        </b-col>
      </b-row>

      <!-- card with Tabs -->
      <b-card-actions
        v-if="product.tax"
        action-collapse
        :title="$t('Fiscal')"
        :show-loading="fetching || saving"
      >
        <div>
          <b-row align-v="center">
            <b-col md="12">
              <b-row>
                <b-col md="5">
                  <FormulateInput
                    id="tax-type"
                    v-model="product.tax.productTaxType"
                    :type="isReadOnly ? 'label' : 'vue-select'"
                    :label="$t('Tipo')"
                    :placeholder="$t('Selecione')"
                    :options="productTaxTypes()"
                  />
                </b-col>
                <b-col md="7">
                  <FormulateInput
                    id="ncm"
                    v-model="product.tax.ncmCode"
                    :type="isReadOnly ? 'label' : 'select-searchable'"
                    :label="$t('NCM')"
                    :placeholder="$t('Digite para pesquisar')"
                    :options="getComboNcms"
                    :start-search="product.tax.ncmCode"
                    autofocus
                    :class="isSaleClassification ? 'required' : ''"
                    :validation="isSaleClassification ? 'required' : ''"
                    :instruction="$t('Digite pelo menos 3 numeros ou letras para iniciar a pesquisa')
                    "
                    @fetchSearch="ncmSearch"
                  />
                </b-col>
                <b-col md="5">
                  <FormulateInput
                    id="origin"
                    v-model="product.tax.productOrigin"
                    :type="isReadOnly ? 'label' : 'vue-select'"
                    :label="$t('Origem')"
                    :placeholder="$t('Selecione')"
                    :options="productOrigins()"
                  />
                </b-col>
                <b-col md="7">
                  <FormulateInput
                    id="cest-id"
                    v-model="product.tax.cestId"
                    :type="isReadOnly ? 'label' : 'select-searchable'"
                    :instruction="$t('Digite pelo menos 3 numeros ou letras para iniciar a pesquisa')
                    "
                    :placeholder="$t('Digite para pesquisar')"
                    :label="$t('CEST')"
                    :options="getComboCests"
                    @fetchSearch="cestSearch"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-card-actions>

      <e-crud-actions
        :show-save="!isReadOnly"
        :show-edit="isReadOnly && $can('Update', 'Product')"
        :busy="saving"
        @save="localSaveProduct"
        @cancel="$router.back()"
        @edit="edit"
      />
    </FormulateForm>
  </section>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormRadioGroup, BTabs, BTab } from 'bootstrap-vue'
import VueBarcode from 'vue-barcode'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { ECrudActions } from '@/views/components'
import { productDomains, uploader } from '@/mixins'
import ProductForm from './components/ProductForm.vue'
import SkuForm from './components/SkuForm.vue'
import TapStationMediaForm from './components/TapStationMediaForm.vue'

export default {
  name: 'ProductMaintain',
  components: {
    BCardActions,
    BRow,
    BCol,
    BTabs,
    BTab,
    BFormGroup,
    BFormRadioGroup,
    ProductForm,
    SkuForm,
    barcode: VueBarcode,
    ECrudActions,
    TapStationMediaForm,
  },

  mixins: [productDomains, uploader],

  data() {
    return {
      fetching: false,
      fetchingPriceTables: false,
      saving: false,
      busy: false,
      imageWideLocal: [],
      imageSquareLocal: [],
      purchaseOrderData: {
        purchaseOrder: null,
        itemIndex: null,
      },
      callbackRouteName: 'product-list',
    }
  },

  computed: {
    ...mapState('pages/catalog/products/productMaintain', [
      'isReadOnly',
      'product',
      'tax',
      'taxCombos',
      'sidebarState',
      'selectedVariants',
      'tieredPrices',
      'tapStationMedia',
      'tapStationMediaDeleted',
    ]),
    ...mapGetters('pages/catalog/products/productMaintain', [
      'productDescription',
      'isSaleClassification',
    ]),
    ...mapGetters('common/ncms', ['getComboNcms']),
    ...mapGetters('common/cests', ['getComboCests']),
    ncm() {
      return this.product?.tax?.ncmCode
    },
    purchaseOrderProduct() {
      return this.purchaseOrderData?.purchaseOrder?.items[this.purchaseOrderData?.itemIndex]
    },
    localTapStationMedia: {
      get() {
        return this.tapStationMedia
      },
      set(val) {
        this.setTapStationMedia(val)
      },
    },
  },

  watch: {
    product(val) {
      this.imageSquareLocal = val?.imageSquare ? [this.getFileInstance(val.imageSquare)] : []
      this.imageWideLocal = val?.imageWide ? [this.getFileInstance(val.imageWide)] : []
    },
    ncm(val) {
      if (val) {
        this.cestSearch(() => { })
      }
    },
  },

  async mounted() {
    await this.cleanState()

    this.setReadOnly(this.$route.name === 'product-view')

    if (this.$route.params.id) {
      await this.getProduct(this.$route.params.id)
      this.$refs.productForm.fillDefaultOptions()
      this.cestSearch(() => { })
    } else {
      this.purchaseOrderData = this.$route.params.purchaseOrderData || {}
      if (this.purchaseOrderData.purchaseOrder) {
        this.product.ean =
          this.purchaseOrderData.purchaseOrder.items[
            this.purchaseOrderData.itemIndex
          ]?.xmlProduct?.eanTaxed
      }
    }
    this.getPriceTables()
    this.callbackRouteName = this.$route.params.callbackRouteName || 'product-list'
    this.$refs.productForm.bindGroupListener()
  },

  methods: {
    ...mapActions('pages/catalog/products', ['cleanCombo', 'fetchProductCombos']),
    ...mapActions('pages/catalog/products/productMaintain', [
      'fetchProduct',
      'saveProduct',
      'fetchPriceTables',
      'cleanState',
      'updateAssociatedSku',
      'setReadOnly',
      'setTapStationMedia',
      'uploadTapStationMedia',
      'deleteTapStationMediaFromServer',
    ]),
    ...mapActions('common/ncms', ['fetchNcms']),
    ...mapActions('common/cests', ['fetchCestsByNcm']),
    ...mapActions('pages/purchase/purchaseOrder/purchaseOrderMaintain', ['updateAssociatedSku']),

    edit() {
      this.$nextTick(() => {
        this.$router.push({ name: 'product-maintain', params: { id: this.$route.params.id } })
      })
    },

    selectGroup(idValue) {
      this.product.subgroup = null
      this.setComboSubgroups(idValue)
    },

    async getProduct(id) {
      try {
        this.fetching = true
        await this.fetchProduct(id)
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    async getPriceTables() {
      try {
        this.fetchingPriceTables = true
        await this.fetchPriceTables()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetchingPriceTables = false
      }
    },

    async ncmSearch(loading, search) {
      try {
        loading(true)
        await this.fetchNcms({ itemSearch: search })
      } catch (e) {
        this.showError({
          message: this.$t('Não foi possível fazer a pesquisa'),
        })
      } finally {
        loading(false)
      }
    },

    async cestSearch(loading, search) {
      if (this.product?.tax?.ncmCode) {
        try {
          loading(true)
          await this.fetchCestsByNcm({ ncm: this.product?.tax?.ncmCode, cestSearch: search })
        } catch (e) {
          this.showError({
            message: this.$t('Não foi possível fazer a pesquisa'),
          })
        } finally {
          loading(false)
        }
      }
    },

    async localSaveProduct() {
      try {
        this.$refs.formProduct.showErrors()
        if (this.$refs.formProduct.isValid) {
          let success = true
          this.saving = true
          const uploadResult = await this.uploadImages()
          const [imageWide, imageSquare] = uploadResult

          const productPayload = {
            ...this.product,
            variations: this.product.variations?.map(variationId => ({ variationId })),
            name: this.productDescription,
            tags: this.product.tags.join(','),
            imageWide: imageWide?.url,
            imageSquare: imageSquare?.url,
          }
          try {
            await this.saveProduct(productPayload)
          } catch (error) {
            success = false
            this.showError({ error })
          }
          if (success) {
            const newPrices = this.$refs.skuForm.getNewPrices()
            if (newPrices.length) {
              try {
                await this.$http({
                  url: '/api/products/skus/update-price',
                  method: 'PUT',
                  data: newPrices,
                })
              } catch (error) {
                success = false
                this.showError({ error })
              }
            }

            await this.processTapStationMedia(this.product)

            if (this.tieredPrices?.length) {
              try {
                const [sku] = this.product.skus
                await this.$http({
                  url: '/api/products/skus/update-tiered-price',
                  method: 'PUT',
                  data: this.tieredPrices
                    .map(tieredPrice => ({
                      ...tieredPrice,
                      priceTableId: +tieredPrice.priceTableId,
                      storeId: tieredPrice.storeId || null,
                      skuId: sku.id,
                    })),
                })
              } catch (error) {
                success = false
                this.showError({ error })
              }
            }
          }
          if (success) {
            this.showSuccess({ message: this.$t('Produto salvo com sucesso') })
            await this.executePosSaveActions()
            this.navigateBack()
          }
        } else {
          this.showInvalidDataMessage()
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    navigateBack() {
      this.$router.push({ name: this.callbackRouteName })
    },

    async updatePurchaseOrder() {
      const [sku] = (await this.$http.get(`/api/skus/search?search=${this.product.ean}`)).data
        .results
      this.updateAssociatedSku({ sku, itemIndex: this.purchaseOrderData.itemIndex })
    },

    async executePosSaveActions() {
      switch (this.callbackRouteName) {
        case 'purchase-order-xml-import':
          this.updatePurchaseOrder()
          break
        default:
          break
      }
    },

    isValidProductData() {
      try {
        const { variations, hasVariations } = this.product

        if (hasVariations) {
          const hasEmptyFieldsVariations = variations.some(vari =>
            this.selectedVariants.some(sv => vari[sv] === undefined || vari[sv] === '')
          )

          if (hasEmptyFieldsVariations) {
            throw new Error(this.$t('Há campos faltando nas variações de produto'))
          }
        }

        return true
      } catch (err) {
        this.showInvalidDataMessage({ message: `<b>${err.message}</b>` })
        return false
      }
    },

    async uploadImages() {
      let resultWide = null
      let resultSquare = null
      const result = await this.upload({
        files: [...this.imageWideLocal, ...this.imageSquareLocal],
        destinationPath: 'images/products',
      })
      if (this.imageWideLocal.length) {
        ;[resultWide] = result
      }
      if (this.imageSquareLocal.length) {
        ;[resultSquare] = result.slice(result.length > 1 ? 1 : 0)
      }
      const nullResult = { url: null }
      return [resultWide || nullResult, resultSquare || nullResult]
    },

    async processTapStationMedia(product) {
      let hasFileError = false
      try {
        await this.uploadTapStationMedia(product?.id)
      } catch (uploadError) {
        this.showError({
          title: this.$t('Ocorreu um erro ao fazer upload das mídias'),
          error: uploadError,
        })
        hasFileError = true
      }

      try {
        await this.deleteTapStationMediaFromServer(product?.id)
      } catch (deleteError) {
        this.showError({
          title: this.$t('Ocorreu um erro ao deletar as mídias'),
          error: deleteError,
        })
        hasFileError = true
      }

      if (
        !hasFileError &&
        (this.tapStationMedia.some(file => !file.id) || this.tapStationMediaDeleted.length > 0)
      ) {
        this.showSuccess({ message: this.$t('Mídias salvas com sucesso.') })
      }
    },

    async removeTapStationMedia(file) {
      this.deleteTapStationMedia(file)
    },
  },
}
</script>

<style lanb="scss" scoped>
.btn-group-toggle label.btn:not(.active) {
  background-color: gray !important;
}
</style>
