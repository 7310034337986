import Vue from 'vue'
import {
  address,
  addressUtils,
  contactUtils,
  deliveryDomain,
  discountTypes,
  payBoxUtils,
  productDomains,
  saleItemReturnedUtils,
  saleItemUtils,
  stringUtils,
} from '@/mixins'
import { localListAddItem, localListDeleteItem, localListUpdateItem } from '@/store/utils'
import { roundDecimal } from '@/utils/number-utils'
import axios from '@axios'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { getInitialSaleItem } from '@/mixins/utils/sale-item-utils'

const getInitialState = () => ({
  order: {
    id: null,
    quotationId: null,
    storeId: null,
    store: null,
    customerId: null,
    customer: null,
    createdDate: null,
    cancelationDate: null,
    cancelationReason: null,
    cancelationUser: null,
    deliveryAddress: address.data().address,
    priceTableId: '1',
    addressSelected: null,
    isDelivery: false,
    deliveryDate: {
      date: null,
      time: null,
    },
    deliveryDateAuth: null,
    origin: null,
    echopeClassification: null,
    status: null,
    isTaxZero: null,
    deliveryStatus: null,
    deliveryObservation: '',
    deliveryFeeType: null,
    deliveryTaxDistance: null,
    deliveryTaxUnitValue: null,
    shippingFee: null,
    shippingFeePerKilometer: null,
    discountType: discountTypes.computed.discountTypesEnum().VALUE,
    comission: 0,
    discount: 0,
    discountAuth: null,
    products: [],
    npsFeedbacks: [],
    npsAnswers: [],
    npsSolved: false,
    isConsigned: false,
    pendingPaymentValue: 0,
    coldOption: null,
  },
  deliveryData: {
    deliveryFeeType: deliveryDomain.data().deliveryFeeEnum.SINGLE_FEE,
    distanceValue: null,
    shippingFee: 0,
    shippingFeePerKilometer: 0,
    deliveryTax: 0,
    discount: 0,
    discountAuth: null,
  },
  minKilometerDelivery: 10,
  itemsToReclaim: [],
  isQuotationReceived: false,
  originalOrder: {
    deliveryDate: {
      date: null,
      time: null,
    },
  },
})

export const getInitialReclaimItem = () => ({
  itemId: null,
  localItemId: null,
  productKitId: null,
  skuProduct: null,
  productAssociated: null,
  quantity: 0,
  returnDateLimit: null,
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    getProducts(state) {
      const itemsProduct = state.order.products
        .filter(p => !p.isDeleted)
        .map(p => ({
          ...p,
          _showDetails: !!p.lendingProducts || p.kitItems?.length > 0,
        }))

      return itemsProduct
    },
    hasLendingProducts(state) {
      return state.itemsToReclaim.some(i => i.productAssociated)
    },
    hasRentProducts(state) {
      const { RENT } = productDomains.computed.productClassificationsEnum()
      return state.order.products.some(p => p.skuProduct.classification === RENT)
    },
    hasReturnedProducts(state, getters) {
      return getters.getReturnedProducts.length > 0
    },
    hasItemsReturned(state) {
      return state.order.itemsReturned?.length > 0
    },
    hasColdOptionProducts(state) {
      return state.order?.products?.some(product => product.hasColdOption)
    },
    isConsignedWithPayment(state) {
      return state.order.isConsigned && state.order.payments?.length > 0
    },

    getReturnedProducts(state) {
      return state.order.returnedProducts
    },
    getTotalReturnedProducts(state, getters) {
      return (getters.getReturnedProducts || []).reduce(
        (total, item) => total + item.priceRuleSelected.priceInfo.localNetValue,
        0
      )
    },

    getCustomerAddressOptions(state) {
      return addressUtils.addressOptionsWithOther(state.order.customer?.addresses)
    },

    getCustomerTelephone(state) {
      return contactUtils.getPricipalMobileAndLandline(state.order.customer?.telephones)
    },

    getTotalProductValue(state) {
      return state.order.products
        .filter(p => !p.isDeleted)
        .reduce((prev, p) => prev + p.priceRuleSelected?.priceInfo?.localNetValue, 0)
    },

    getOrderTotalValue(state, getters) {
      const { isDelivery, isTaxZero, discountType, discount, comission } = state.order
      const { deliveryTax, discount: deliveryDiscount } = state.deliveryData
      const totalProducts = getters.getTotalProductValue
      const totalDelivery = isDelivery && !isTaxZero ? deliveryTax : 0

      const discountValue = payBoxUtils.getDiscountValue({
        discountType,
        discount,
        value: totalProducts,
      })
      return totalProducts - (discountValue + (deliveryDiscount || 0)) + totalDelivery + comission
    },

    getOrderTotalDiscount(state, getters) {
      const { discountType, discount } = state.order
      const { discount: deliveryDiscount } = state.deliveryData
      const totalProducts = getters.getTotalProductValue
      const totalProductDiscount = getters.getProducts.reduce(
        (prev, p) => prev + p.priceRuleSelected?.priceInfo?.localDiscountTotal,
        0
      )

      const discountValue = payBoxUtils.getDiscountValue({
        discountType,
        discount,
        value: totalProducts,
      })
      return totalProductDiscount + discountValue + (deliveryDiscount || 0)
    },

    getOrderTotalDeliveryTax(state) {
      const { isTaxZero } = state.order
      const { deliveryTax } = state.deliveryData
      return isTaxZero ? 0 : deliveryTax
    },

    getMaxScheduleDays(state) {
      return state.order?.store?.maxScheduleDays || 1
    },
    getDateOfMaxSchedule(state, getters) {
      return moment().add(getters.getMaxScheduleDays, 'd').endOf('day').toDate()
    },

    hasDoubleDelivery(state, getters) {
      const { isConsigned, isDelivery } = state.order
      if (!isDelivery) return false
      if (isConsigned) return true

      const { RENT, LENDING } = productDomains.computed.productClassificationsEnum()

      const kitItems = getters.getProducts.flatMap(p => p.kitItems || [])
      const allProducts = kitItems.concat(getters.getProducts)

      const hasLendingOrRent = allProducts.some(
        p =>
          p.lendingProducts.length > 0 ||
          p.skuProduct.classification === RENT ||
          p.skuProduct.classification === LENDING
      )
      return hasLendingOrRent
    },
  },

  mutations: {
    SET_ORDER_ID(state, { orderId }) {
      state.order.id = orderId
    },
    SET_ORDER(state, { order }) {
      state.order = order
    },
    SET_ORDER_STORE_ID(state, { storeId }) {
      state.order.storeId = storeId
    },
    SET_ORDER_STORE(state, { store }) {
      state.order.store = store
    },
    SET_ORDER_CUSTOMER(state, { customer }) {
      state.order.customerId = String(customer?.id)
      state.order.customer = customer
    },
    SET_ORDER_PRICE_TABLE_ID(state, { priceTableId }) {
      const { getStringOrNull } = stringUtils.methods
      state.order.priceTableId = getStringOrNull(priceTableId) || '1'
    },
    SET_PRODUCTS(state, { products }) {
      state.order.products = products
    },
    SET_ITEMS_TO_RECLAIM(state, { itemsToReclaim }) {
      state.itemsToReclaim = itemsToReclaim
    },
    SET_DELIVERY_TAX(state, deliveryTax) {
      state.deliveryData.deliveryTax = deliveryTax
    },
    SET_DELIVERY_DATA_FEES(state, { shippingFee, shippingFeePerKilometer }) {
      state.deliveryData.shippingFee = shippingFee
      state.deliveryData.shippingFeePerKilometer = shippingFeePerKilometer
    },
    SET_DELIVERY_DATE(state, { date, time }) {
      state.order.deliveryDate.date = date
      state.order.deliveryDate.time = time === undefined ? state.order.deliveryDate.time : time
    },
    SET_DELIVERY_DATE_AUTH(state, delegateToken) {
      state.order.deliveryDateAuth = delegateToken
    },
    SET_DELIVERY_DATA(state, deliveryData) {
      state.deliveryData = deliveryData
    },
    SET_IS_QUOTATION_RECEIVED(state, isQuotationReceived) {
      state.isQuotationReceived = isQuotationReceived
    },

    SET_DELIVERY_ADDRESS(state, { addressData }) {
      state.order.deliveryAddress = addressData || getInitialState().order.deliveryAddress
    },

    SET_ORIGINAL_DELIVERY_DATE(state, { date, time }) {
      const { deliveryDate } = getInitialState().originalOrder
      state.originalOrder.deliveryDate.date = date || deliveryDate.date
      state.originalOrder.deliveryDate.time = time || deliveryDate.time
    },

    CLEAN_STATE(state) {
      const { order, deliveryData, isQuotationReceived, itemsToReclaim, originalOrder } =
        getInitialState()
      state.order = order
      state.itemsToReclaim = itemsToReclaim
      state.deliveryData = deliveryData
      state.isQuotationReceived = isQuotationReceived
      state.originalOrder = originalOrder
    },

    CLEAN_DELIVERY_ADDRESS(state) {
      const { deliveryAddress } = getInitialState().order
      state.order.deliveryAddress = deliveryAddress
    },

    CLEAN_ORDER_CUSTOMER(state) {
      const { customer, customerId } = getInitialState().order
      state.order.customer = customer
      state.order.customerId = customerId
    },

    CLEAN_INPUTS_AUTH(state) {
      const { discount, discountAuth } = getInitialState().order
      const { unitDiscount, unitDiscountAuth } = getInitialSaleItem()
      state.order.discountAuth = discountAuth
      state.order.discount = discount
      state.order.products = state.order.products.map(p => ({
        ...p,
        unitDiscount,
        unitDiscountAuth,
      }))
    },

    SET_MIN_KILOMETER(state, minKilometer) {
      if (minKilometer) {
        state.minKilometerDelivery = parseInt(minKilometer, 10)
      }
    },
    ADD_NPS_FEEDBACK(state, npsFeedback) {
      if (!state.order.npsFeedbacks) {
        Vue.set(state.order, 'npsFeedbacks', [])
      }
      state.order.npsFeedbacks.push(npsFeedback)
    },
  },

  actions: {
    async fetchOrderById({ commit, dispatch }, id) {
      commit('CLEAN_STATE')
      const { data } = await axios.get(`/api/sales/orders/${id}`)
      const { FEE_PER_KILOMETER, SINGLE_FEE } = deliveryDomain.data().deliveryFeeEnum

      const deliveryOrder = await dispatch('prepareDeliveryData', {
        delivery: data.delivery,
        deliveryAddress: data.deliveryAddress,
        customer: data.customer,
        deliveryDate: data.deliveryDate,
        isConsigned: data.consigned,
        isQuotation: false,
      })

      const shippingValues = {
        shippingFee: data.deliveryFeeType === SINGLE_FEE ? data.deliveryTaxUnitValue : null,
        shippingFeePerKilometer:
          data.deliveryFeeType === FEE_PER_KILOMETER ? data.deliveryTaxUnitValue : null,
      }
      const initialState = getInitialState()
      const deliveryData = {
        ...initialState.deliveryData,
        deliveryFeeType: data.deliveryFeeType || initialState.deliveryData.deliveryFeeType,
        distanceValue: data.deliveryTaxDistance || 0,
        deliveryTax: data.deliveryTax,
        discount: data.deliveryTaxDiscountValue,
        ...shippingValues,
      }

      const itemsFormated = saleItemUtils.buildSaleItemsAndKitsFromApi(
        data.items,
        data.itemKits,
        data.itemsReturned
      )
      const returnedItemsFormated =
        data.itemsReturned?.map(saleItemReturnedUtils.buildSaleItemReturnedFromApi) || []

      const order = {
        ...initialState.order,
        ...data,
        id: data.id,
        status: data.status,
        origin: data.origin,
        coldOption: data.coldOption,
        echopeClassification: data.echopeClassification,
        createdDate: data.createdDate,
        cancelationDate: data.cancelationDate,
        cancelationReason: data.cancelationReason,
        cancelationUser: data.cancelationUser,
        invoice: data.invoice,
        deliveryStatus: data.deliveryStatus,
        quotationId: data.quotationId || null,
        barTabConsumptionId: data.barTabConsumptionId || null,
        storeId: String(data.store.id),
        priceTableId: String(data.priceTable?.id) || null,
        customerId: String(data.customer?.id),
        customer: {
          ...data.customer,
          label: data.customer.name,
        },
        isConsigned: data.consigned,
        isDelivery: data.delivery,
        isTaxZero: data.isTaxZero,
        deliveryObservation: data.deliveryObservation,
        deliveryFeeType: data.deliveryFeeType,
        deliveryTaxDistance: data.deliveryTaxDistance,
        deliveryTaxUnitValue: data.deliveryTaxUnitValue,
        products: itemsFormated,
        returnedProducts: returnedItemsFormated,
        itemsReturned: data.itemsReturned,
        payments: data.payments,
        onlinePayment: data.onlinePayment,
        onlinePaymentCancelation: data.onlinePaymentCancelation,
        pendingPaymentValue: data.pendingPaymentValue,
        discountType: data.discountType || discountTypes.computed.discountTypesEnum().VALUE,
        discount: data.discount,
        comission: data.comission,
        ...deliveryOrder,
        ...shippingValues,
      }

      await dispatch('updateReclaimItem', { itemList: itemsFormated })
      commit('SET_ORDER', { order })
      commit('SET_DELIVERY_DATA', deliveryData)
      await dispatch('fetchStoreById', { storeId: order.storeId })

      commit('SET_ORIGINAL_DELIVERY_DATE', deliveryOrder.deliveryDate || {})
    },

    async fetchStoreById({ commit, dispatch }, { storeId }) {
      const { data } = await axios.get(`/api/stores/${storeId}`)
      commit('SET_ORDER_STORE', { store: data })

      await dispatch('reloadDeliveryData')
    },

    async fetchCustomerById({ commit, getters, state, dispatch }, { customerId }) {
      if (state.order.customer?.id?.toString() === customerId) return

      const { data } = await axios.get(`/api/customers/${customerId}/read-only`)
      commit('SET_ORDER_CUSTOMER', { customer: data })

      if (!getters.isConsignedWithPayment) {
        const validPriceTable = await dispatch(
          'common/priceTables/getPriceTableActiveOrDefaultId',
          { priceTableId: data?.priceTableId },
          { root: true }
        )
        commit('SET_ORDER_PRICE_TABLE_ID', { priceTableId: validPriceTable.id })
      }
    },

    setOrderStoreId({ commit }, { storeId }) {
      commit('SET_ORDER_STORE_ID', { storeId })
    },
    setDeliveryAddress({ commit }, { addressData }) {
      commit('SET_DELIVERY_ADDRESS', { addressData })
    },
    setIsQuotationReceived({ commit }, isReceived) {
      commit('SET_IS_QUOTATION_RECEIVED', isReceived)
    },
    setDeliveryDateAuth({ commit }, delegateToken) {
      commit('SET_DELIVERY_DATE_AUTH', delegateToken)
    },

    async upsertProduct({ commit, state, dispatch }, { formData }) {
      const isEdit = Boolean(formData.localId || formData.id)

      let localFormData = saleItemUtils.updateSaleItemOrSaleKit(formData, {
        quantity: formData.quantity,
        unitDiscount: formData.unitDiscount,
        discountType: formData.discountType,
      })

      const { priceRuleSelected } = localFormData
      const isPromotionalPrice = priceRuleSelected.type === payBoxUtils.priceRuleTypes.PROMOTION

      let newQuantity = localFormData.quantity
      let quantityWithoutPromotion = 0
      if (isPromotionalPrice) {
        const { promotionQuantityAvailable, promotionQuantityUnlimited } =
          priceRuleSelected.promotion

        if (!promotionQuantityUnlimited) {
          if (localFormData.id) {
            const reserved = localFormData.maxQuantity > 0 ? localFormData.maxQuantity : 0
            const available = (promotionQuantityAvailable || 0) + reserved
            // cenário que está sendo atualizado um produto com promoção adicionado no pedido
            if (newQuantity > available) {
              quantityWithoutPromotion = newQuantity - (available || 0)
              newQuantity =
                quantityWithoutPromotion > 0 ? newQuantity - quantityWithoutPromotion : newQuantity
            }
          } else {
            quantityWithoutPromotion = newQuantity - (promotionQuantityAvailable || 0)
            newQuantity =
              quantityWithoutPromotion > 0 ? newQuantity - quantityWithoutPromotion : newQuantity
          }
        }
      }

      if (newQuantity !== localFormData.quantity) {
        localFormData = saleItemUtils.updateSaleItemOrSaleKit(localFormData, {
          quantity: newQuantity,
        })
      }

      const itemData = {
        ...localFormData,
        quantity: newQuantity,
      }

      const isInTieredPriceRange = itemData.skuTieredPriceId
      if(isInTieredPriceRange) {
        itemData.price = itemData.skuProduct?.tieredPrices?.find(tp =>
          itemData.quantity >= tp.quantityFrom
          && itemData.quantity <= tp.quantityTo
        )?.price
      }

      let itemList = state.order.products
      if (isEdit) {
        itemList = localListUpdateItem(state.order.products, {
          ...itemData,
          hasColdOption: itemData.skuProduct?.hasColdOption,
        })
      } else if (itemData.quantity > 0) {
        itemList = localListAddItem(state.order.products, {
          ...itemData,
          hasColdOption: itemData.skuProduct?.hasColdOption,
        })
      }

      commit('SET_PRODUCTS', { products: itemList })
      dispatch('updateReclaimItem', { itemList })

      if (quantityWithoutPromotion > 0) {
        dispatch('addNewItemWithoutPromotion', { newItem: itemData, quantityWithoutPromotion })
      }
    },

    addProduct({ dispatch }, { formData }) {
      dispatch('upsertProduct', { formData })
      dispatch('updateDeliveryTax')
    },
    updateProduct({ dispatch }, { formData }) {
      dispatch('upsertProduct', { formData })
    },
    removeProduct({ commit, state, dispatch }, { id }) {
      if (!id) throw new Error('Item sem id')
      const itemList = localListDeleteItem(state.order.products, id)

      commit('SET_PRODUCTS', { products: itemList })
      dispatch('updateReclaimItem', { itemList })
      dispatch('updateDeliveryTax')
    },
    async addNewItemWithoutPromotion({ dispatch, state }, { newItem, quantityWithoutPromotion }) {
      const localItemFound = saleItemUtils.findLocalItemWithoutPromotion(
        state.order.products,
        newItem
      )
      if (localItemFound) {
        await dispatch('updateProduct', {
          formData: {
            ...localItemFound,
            quantity: localItemFound.quantity + quantityWithoutPromotion,
          },
        })

        return
      }

      const { ean } = newItem.skuProduct
      const { storeId, priceTableId } = state.order
      const { data } = await axios.get(`/api/sales/pay-box-sku/store/${storeId}/ean/${ean}`, {
        params: { priceTableId },
      })

      const newItemBuilded = saleItemUtils.buildProductToSaleItem(
        { ...data, promotionId: null },
        { quantity: quantityWithoutPromotion }
      )

      await dispatch('addProduct', {
        formData: {
          ...newItemBuilded,
        },
      })
    },

    reloadDeliveryData({ commit, state, getters }) {
      const {
        shippingFee: storeShippingFee,
        shippingFeePerKilometer: storeShippingFeePerKilometer,
      } = state.order.store.shippingFees
      commit('SET_DELIVERY_DATA_FEES', {
        shippingFee: state.order.shippingFee ?? storeShippingFee,
        shippingFeePerKilometer:
          state.order.shippingFeePerKilometer ?? storeShippingFeePerKilometer,
      })

      if (!state.order.id && state.order.isDelivery && state.order.deliveryDate?.date) {
        const date = moment(state.order.deliveryDate?.date)
        if (date.isValid() && getters.getDateOfMaxSchedule < date.toDate()) {
          commit('SET_DELIVERY_DATE', { date: null })
        }
      }
    },
    updateDeliveryTax({ state, commit, getters }) {
      const { FEE_PER_KILOMETER } = deliveryDomain.data().deliveryFeeEnum
      const { deliveryFeeType, distanceValue, shippingFee, shippingFeePerKilometer } =
        state.deliveryData
      let totalTax = shippingFee

      if (deliveryFeeType === FEE_PER_KILOMETER) {
        totalTax = roundDecimal(shippingFeePerKilometer * (distanceValue || 0))
      }

      if (getters.hasDoubleDelivery) {
        totalTax *= 2
      }

      commit('SET_DELIVERY_TAX', totalTax)
    },

    updateReclaimItem({ commit, state }, { itemList }) {
      const { RENT, LENDING } = productDomains.computed.productClassificationsEnum()
      const validItems = itemList.filter(
        i =>
          !i.isDeleted &&
          (i.lendingProducts.length > 0 ||
            i.skuProduct?.classification === RENT ||
            i.skuProduct?.classification === LENDING)
      )

      let reclaimItemsInKit = []
      const itemKits = itemList.filter(kItem => !kItem.isDeleted && kItem.kitItems.length > 0)
      if (itemKits.length > 0) {
        const itensInKit = itemKits.map(i => i.kitItems).flat()

        reclaimItemsInKit = itensInKit.filter(
          kitItem =>
            kitItem.skuProduct?.classification === RENT ||
            kitItem.skuProduct?.classification === LENDING ||
            kitItem.lendingProducts?.length > 0
        )
      }

      const toReclaimList = [...validItems, ...reclaimItemsInKit].map(i => {
        const isItemFromKit = i.productKitId != null

        let item =
          i.skuProduct?.classification === RENT || i.skuProduct?.classification === LENDING
            ? i
            : i.lendingProducts[0]
        let kit = null
        if (isItemFromKit) {
          item = i?.lendingProducts?.length > 0 ? i.lendingProducts[0] : i
          kit = itemKits.find(
            kItem =>
              (kItem.isKit || kItem.skuProduct.kit) && kItem.skuProduct.id === i.productKitId
          )
        }

        const data = {
          itemId: item.id,
          localItemId: item.localId,
          productKitId: isItemFromKit ? i.productKitId : null,
          productAssociated: isItemFromKit && kit ? kit.skuProduct : i.skuProduct,
          skuProduct: item?.skuProduct,
          quantity: item.quantity,
        }

        const itemId = item.localId || item.id
        const toReclaimIndex = state.itemsToReclaim.findIndex(
          tr => tr.localItemId === itemId || tr.itemId === itemId
        )

        if (toReclaimIndex > -1) {
          const itemReclaim = state.itemsToReclaim[toReclaimIndex]
          return {
            ...getInitialReclaimItem(),
            ...data,
            returnDateLimit: itemReclaim.returnDateLimit,
            returnDate: itemReclaim.returnDate,
            returnQuantityDamaged: itemReclaim.returnQuantityDamaged || 0,
          }
        }

        return {
          ...getInitialReclaimItem(),
          ...data,
          returnDateLimit: item?.returnDateLimit,
          returnDate: item?.returnDate,
          returnQuantityDamaged: item?.returnQuantityDamaged || 0,
          returnUser: item?.returnUser,
        }
      })

      commit('SET_ITEMS_TO_RECLAIM', { itemsToReclaim: toReclaimList })
    },

    async updateProductPrices({ state, commit }) {
      const { storeId, products, priceTableId } = state.order

      if (!storeId) return

      // TODO usar endpoint que mande lista de ean ao inves de fazer varias requests
      const productsPromise = products.map(async p => {
        const { data } = await axios.get(
          `/api/sales/pay-box-sku/store/${storeId}/ean/${p.skuProduct.ean}`,
          {
            params: {
              priceTableId,
            },
          }
        )

        const refreshedSaleItem = saleItemUtils.saleItemForceRefreshUsingProduct(p, data)

        return refreshedSaleItem
      })

      const productsUpdated = await Promise.all(productsPromise)

      commit('SET_PRODUCTS', { products: productsUpdated })
    },

    async saveOrder({ state, getters, commit }) {
      const {
        id,
        storeId,
        customer,
        deliveryAddress,
        priceTableId,
        products,
        isDelivery,
        isConsigned,
        quotationId,
        deliveryObservation,
        deliveryDate,
        discount,
        discountType,
        echopeClassification,
        coldOption,
      } = state.order
      const { deliveryData, itemsToReclaim } = state
      const { RENT, LENDING } = productDomains.computed.productClassificationsEnum()

      if (getters.getOrderTotalValue < 0) throw new Error('Total do pedido está negativo.')
      else if (products.length <= 0) throw new Error('Não foi adicionado Produtos para o pedido.')

      const items = products
        .map(item => {
          const { skuProduct: itemSkuProduct, priceRuleSelected } = item
          if (item.kitItems.length > 0) {
            return item.kitItems
              .map(kitItem => {
                const kitItemLending =
                  kitItem.lendingProducts?.length > 0 ? kitItem.lendingProducts[0] : null

                const auxItemId = kitItemLending?.localId || kitItemLending?.id
                const toReclaimIndex = itemsToReclaim.findIndex(
                  tr => tr.localItemId === auxItemId || tr.itemId === auxItemId
                )

                const kitItemToSave = {
                  id: kitItem.id,
                  skuId: kitItem.skuProduct.skuId,
                  quantity: kitItem.quantity,
                  discountPriceTable: 0, // item do kit seta pro desconto da tabela de preço default.
                  productKitId: kitItem.priceRuleSelected.productKit.id,
                  priceTableId: kitItem.priceRuleSelected.priceTable.id,
                  originalUnitValue: kitItem.priceRuleSelected.priceInfo.originalPrice,
                  unitValue: kitItem.priceRuleSelected.priceInfo.unitValue,
                  contractualFine: kitItem.priceRuleSelected.priceInfo.contractualFine,
                  discountType: kitItem.priceRuleSelected.priceInfo.discountType,
                  unitDiscount: kitItem.priceRuleSelected.priceInfo.unitDiscount,
                  isDeleted: item.isDeleted,
                }

                if (kitItemLending) {
                  return [
                    kitItemToSave,
                    {
                      id: kitItemLending.id,
                      skuAssociatedId: kitItemToSave.skuId,
                      skuId: kitItemLending.skuProduct.skuId,
                      productKitId: kitItemLending.skuProduct.productKitId,
                      discountPriceTable: 0, // item do kit seta pro desconto da tabela de preço default.
                      quantity: kitItemLending.quantity,
                      priceTableId: kitItemLending.priceRuleSelected.priceTable.id,
                      originalUnitValue: kitItemLending.priceRuleSelected.priceInfo.originalPrice,
                      unitValue: kitItemLending.priceRuleSelected.priceInfo.unitValue,
                      contractualFine: kitItemLending.priceRuleSelected.priceInfo.contractualFine,
                      discountType: kitItemLending.priceRuleSelected.priceInfo.discountType,
                      unitDiscount: kitItemLending.priceRuleSelected.priceInfo.unitDiscount,
                      isDeleted: item.isDeleted,
                      returnDateLimit: itemsToReclaim[toReclaimIndex]?.returnDateLimit || null,
                    },
                  ]
                }

                // quando for item comodato sem associação com outro item
                kitItemToSave.returnDateLimit =
                  itemsToReclaim[toReclaimIndex]?.returnDateLimit || null
                return [kitItemToSave]
              })
              .flat()
          }

          const auxItemId = item.localId || item.id
          const toReclaimRentIndex = itemsToReclaim.findIndex(
            tr =>
              ((tr.productAssociated.classification === RENT ||
                tr.productAssociated.classification === LENDING) &&
                tr.localItemId === auxItemId) ||
              tr.itemId === auxItemId
          )

          return {
            id: item.id,
            skuId: itemSkuProduct.skuId,
            productKitId: null,
            quantity: item.quantity,
            skuAssociatedId: null,

            promotionId: priceRuleSelected.promotion?.id,
            skuTieredPriceId: priceRuleSelected.tieredPrice?.id,
            priceTableId: priceRuleSelected.priceTable.id,

            unitValue: priceRuleSelected.priceInfo.unitValue,
            originalUnitValue: priceRuleSelected.priceInfo.originalPrice,
            contractualFine: priceRuleSelected.priceInfo.contractualFine,
            discountType: priceRuleSelected.priceInfo.discountType,
            unitDiscount: priceRuleSelected.priceInfo.unitDiscount,
            discountPriceTable: priceRuleSelected.priceTable.discount,

            isDeleted: item.isDeleted,
            returnDateLimit: itemsToReclaim[toReclaimRentIndex]?.returnDateLimit || null,
          }
        })
        .flat()

      const itemKits = products
        .filter(item => item.kitItems?.length > 0)
        .map(item => ({
          id: item.id || 0,
          productKitId: item.priceRuleSelected.productKit.id,
          quantity: item.quantity,
          unitValue: item.priceRuleSelected.priceInfo.unitValue,
          isDeleted: item.isDeleted,
        }))

      const lendingProducts = products
        .filter(item => item.lendingProducts?.length > 0)
        .map(item => {
          const lendingItem = item.lendingProducts[0]
          const auxItemId = lendingItem.localId || lendingItem.id
          const toReclaimIndex = itemsToReclaim.findIndex(
            tr => tr.localItemId === auxItemId || tr.itemId === auxItemId
          )

          return {
            id: lendingItem.id,
            skuId: lendingItem.skuProduct.skuId,
            productKitId: null,
            skuAssociatedId: item.skuProduct.skuId,
            quantity: item.quantity,
            unitValue: lendingItem.priceRuleSelected.priceInfo.unitValue,
            contractualFine: lendingItem.priceRuleSelected.priceInfo.contractualFine,
            priceTableId: lendingItem.priceRuleSelected.priceTable.id,
            discountType: null,
            discountPriceTable: null,
            unitDiscount: null,
            returnDateLimit:
              toReclaimIndex > -1 ? itemsToReclaim[toReclaimIndex].returnDateLimit : null,
            isDeleted: item.isDeleted,
          }
        })

      let delivery = {}
      if (isDelivery || isConsigned) {
        const momentDate = moment(deliveryDate.date)?.format('YYYY-MM-DD')
        const time = deliveryDate.time || moment().format('HH:mm')
        const deliveryDateTimeFormatted = moment(
          `${momentDate} ${time}`,
          'YYYY-MM-DD HH:mm'
        ).format()

        delivery = {
          delivery: isDelivery,
          deliveryTax: isDelivery ? deliveryData.deliveryTax : null,
          deliveryAddress: isDelivery ? deliveryAddress : null,
          deliveryObservation,
          deliveryDate: deliveryDateTimeFormatted,
          deliveryFeeType: deliveryData.deliveryFeeType,
          deliveryTaxDistance: deliveryData.distanceValue,
          deliveryTaxUnitValue:
            deliveryData.deliveryFeeType === deliveryDomain.data().deliveryFeeEnum.SINGLE_FEE
              ? deliveryData.shippingFee
              : deliveryData.shippingFeePerKilometer,
          deliveryTaxDiscountValue: deliveryData.discount,
        }
      }

      const body = {
        id,
        storeId,
        priceTableId,
        customerId: customer.id,
        quotationId,
        items: [...items, ...lendingProducts],
        itemKits,
        discount,
        discountType,
        consigned: isConsigned,
        echopeClassification,
        coldOption,
        ...delivery,
      }

      const getDelegateToken = state.order.deliveryDateAuth?.token

      const { data: saveReturn } = await axios({
        url: '/api/sales/orders',
        method: id ? 'PUT' : 'POST',
        data: body,
        headers: {
          'delegate-token': getDelegateToken,
        },
      })

      commit('SET_ORDER_ID', { orderId: saveReturn.id })
      return saveReturn
    },

    async updateCustomer({ state }) {
      await axios({
        url: `/api/sales/orders/${state.order.id}/update-customer/${state.order.customer.id}`,
        method: 'PUT',
      })
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
    cleanDeliveryAddress({ commit }) {
      commit('CLEAN_DELIVERY_ADDRESS')
    },
    cleanOrderCustomer({ commit }) {
      commit('CLEAN_ORDER_CUSTOMER')
    },
    cleanInputsAuth({ commit }) {
      // TODO melhoria: verificar se usuário que autorizou campos na loja possui permissão na outra loja.
      commit('CLEAN_INPUTS_AUTH')
    },

    resetToOriginal({ state, commit }, { type }) {
      switch (type) {
        case 'deliveryDate':
          commit('SET_DELIVERY_DATE', state.originalOrder.deliveryDate)
          break
        default:
      }
    },

    async quotationToOrder({ state, commit, rootState, dispatch }) {
      commit('CLEAN_STATE')
      const { quotation, deliveryData } = rootState.pages.sale.quotation.quotationMaintain
      await new Promise(resolve => setTimeout(() => resolve(), 100))

      const initialItem = getInitialSaleItem()
      const initialOrder = getInitialState().order

      const formatLendingQuotationItem = lendingProducts => {
        if (lendingProducts?.length > 0) {
          return lendingProducts.map(lp => ({
            ...lp,
            quotationItemId: lp.id,
            id: null,
            localId: uuid(),
          }))
        }
        return initialItem.lendingProducts
      }

      const products = quotation.products.map(item => {
        let kitItemsFormated = initialItem.kitItems
        if (item.kitItems?.length > 0) {
          kitItemsFormated = item.kitItems.map(ki => ({
            ...ki,
            id: null,
            localId: uuid(),
            quotationItemId: ki.id,
            lendingProducts: formatLendingQuotationItem(ki.lendingProducts),
          }))
        }

        const { priceRuleSelected } = item
        const { priceInfo } = priceRuleSelected

        const itemFormated = {
          ...initialItem,
          quotationItemId: item.id,
          localId: uuid(),
          skuProduct: {
            ...item.skuProduct,
            label: item.skuProduct.name,
          },
          priceTable: priceRuleSelected.priceTable,
          discountPriceTable: priceRuleSelected.discount,
          promotionId: priceRuleSelected.promotion?.id,
          kitItems: kitItemsFormated,
          lendingProducts: formatLendingQuotationItem(item.lendingProducts),
          quantity: item.quantity,
          discountType: priceInfo.discountType,
          unitDiscount: priceInfo.unitDiscount || 0,
        }

        return { ...itemFormated, priceRuleSelected }
      })

      const delivery = await dispatch('prepareDeliveryData', {
        delivery: quotation.isDelivery,
        deliveryAddress: quotation.deliveryAddress,
        customer: quotation.customer,
        isQuotation: true,
      })

      const order = {
        ...initialOrder,
        quotationId: quotation.id || null,
        storeId: String(quotation.storeId),
        priceTableId: quotation.priceTableId,
        isDelivery: quotation.isDelivery,
        isConsigned: quotation.isConsigned,
        discountType: quotation.discountType,
        discount: quotation.discount,
        products,
        ...delivery,
      }

      commit('SET_ORDER', { order })
      await dispatch('fetchCustomerById', { customerId: quotation.customerId })
      await dispatch('updateProductPrices')
      await dispatch('updateReclaimItem', { itemList: state.order.products })
      commit('SET_DELIVERY_DATA', deliveryData)
      await dispatch('fetchStoreById', { storeId: order.storeId })
      await dispatch('updateDeliveryTax')
    },

    prepareDeliveryData(
      states,
      { delivery, deliveryAddress, customer, deliveryDate, isQuotation, isConsigned }
    ) {
      const initialOrder = getInitialState().order
      let deliveryData = {
        deliveryAddress: initialOrder.deliveryAddress,
        addressSelected: initialOrder.addressSelected,
      }

      if (delivery) {
        const addressLabel = address.methods.formatAddress(deliveryAddress)
        const hasInAddressOptions = customer.addresses
          .map(ad => address.methods.formatAddress(ad))
          .findIndex(ad => ad === addressLabel)

        const addressSelected =
          hasInAddressOptions > -1
            ? addressUtils.makeAddressOption(deliveryAddress)
            : addressUtils.otherAddress()

        deliveryData = {
          deliveryAddress,
          addressSelected,
        }
      }

      if (isConsigned || (delivery && !isQuotation)) {
        deliveryData.deliveryDate = {
          date: moment(deliveryDate).format('yyyy-MM-DD'),
          time: moment(deliveryDate).format('HH:mm'),
        }
      }

      return deliveryData
    },

    async fetchMinKilometerParam({ commit }) {
      const { data } = await axios.get('/api/parameter/order.delivery.min_kilometer')
      commit('SET_MIN_KILOMETER', data?.value || 10)
    },

    addNpsFeedback({ commit }, npsFeedback) {
      commit('ADD_NPS_FEEDBACK', npsFeedback)
    },
  },
}
